// Firebase

// Import the functions you need from the SDKs you need

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyAzjdwu78ZiMioTTm_69VZ-GecuTMpW1rc",
    authDomain: "entrevistron.firebaseapp.com",
    projectId: "entrevistron",
    storageBucket: "entrevistron.appspot.com",
    messagingSenderId: "942191565687",
    appId: "1:942191565687:web:3216a169e8487797659f72"
};


export default firebaseConfig