<script setup>
import { ref, onMounted, onBeforeUnmount, onBeforeMount, onUnmounted } from "vue";
import { useAuthStore } from '@/stores/auth.js';
import { useRouter } from "vue-router";


const router = useRouter()
const authStore = useAuthStore();
const mobile = ref(false);
const scrolledNav = ref(0);
const mobileNav = ref(false);
let windowWidth = window.innerWidth;

function checkScreen() {
  windowWidth = window.innerWidth;
  if (windowWidth <= 640) {
    mobile.value = true;
  } else {
    mobile.value = false;
    mobileNav.value = false;
  }
}

function updateScroll() {
  scrolledNav.value = window.scrollY;
  if (scrolledNav.value > 0) {
    mobile.value = true;
  } else {
    mobile.value = false;
  }
}



onMounted(() => {
  window.addEventListener("scroll", updateScroll);
  updateScroll();
});

onBeforeUnmount(() => {
  window.removeEventListener("scroll", updateScroll);
});

onBeforeMount(() => {
  if (!authStore.currentUserEmail) {
    router.push("/login")
  }
  window.addEventListener("resize", checkScreen);
  checkScreen();
  
});

onUnmounted(() => {
  window.removeEventListener("resize", checkScreen);
});



</script>

<template>
  <header
    v-if="authStore.currentUserEmail"
    :class="{ 'bg-[#1D1E1E] bg-opacity-100': !mobile, 'bg-black bg-opacity-90': mobile, 'scrolled-nav': scrolledNav }"
    class="w-full fixed z-50 transition-all duration-500 text-white">
    <nav class="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8 p-3">
      <div class="relative flex items-center justify-end">
        
        <ul class="navigation flex space-x-4 text-sm font-semibold items-center">
          <li  class="hover:text-[#271935]">
            <router-link :to="{ name: 'home' }">Home</router-link>
          </li>
          <p >|</p>
          <li class="hover:text-[#271935]" @click="authStore.signout">
            <router-link :to="{ name: 'login' }">Log out</router-link>
          </li>
        </ul>
      </div>
    </nav>
  </header>
</template>


