import { defineStore } from 'pinia'
import { getAuth, signOut, createUserWithEmailAndPassword, signInWithEmailAndPassword, onAuthStateChanged, sendPasswordResetEmail, sendEmailVerification } from "firebase/auth";
import { ref, watchEffect } from 'vue';
import { useCrudStore } from '@/stores/crudUser.js';


export const useAuthStore = defineStore('auth', () => {

    // This variable is used to store the session status
    const sessionStatus = ref(sessionStorage.getItem('sessionStatus') || false);

    // This variable is used to store the current user's email
    const currentUserEmail = ref(sessionStorage.getItem('currentUserEmail') || '');

    const user = ref({
        email: '',
        password: '',
        repassword: '',
        errorMessage: ''
    });
    /* global Swal */

    const register = (name, birthYear, email, password, flag) => {
        const auth = getAuth();
        if (flag == true) {
            createUserWithEmailAndPassword(auth, email, password)
                .then(() => {
                    Swal.fire({
                        title: "User register!",
                        icon: "success",
                    });

                    sendEmailVerification(auth.currentUser)
                        .then(() => {
                            useCrudStore().addUser(name, birthYear, email);
                        })
                        .catch((error) => {
                            // Ocurrió un error al enviar el correo de verificación
                            console.error('Error al enviar el correo de verificación', error);
                        });

                    // Redirect to page in 3 seconds
                    setTimeout(function () {
                        window.location.href = "/login";
                    }, 3000);
                })
                .catch((error) => {
                    let errorMessage = error.message;
                    console.log(errorMessage);
                    Swal.fire({
                        title: "Failed to register user!",
                        icon: "error",
                    });
                });
        } else {
            console.log("Error en el registro");
        }
    };

    const login = (email, password) => {
        const auth = getAuth();

        signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                if (userCredential.user.emailVerified) {
                    Swal.fire({
                        title: "Logged in!",
                        icon: "success",
                    },
                    
                    );
                    setTimeout(() => {
                        // Signed in
                        currentUserEmail.value = userCredential.user.email;
                        // Crear una variable en el almacenamiento local y almacenar un valor
                        localStorage.setItem("emailStatus", user.value.emailVerified);
                        // Redirect to page in 3 seconds
                        window.location.href = "/";
                    }, 3000);
                } else {
                    Swal.fire({
                        title: "Please verify your email!",
                        icon: "warning",
                        text: "Resend verification link?",
                        showCancelButton: true,
                        confirmButtonText: "Yes, send",
                        cancelButtonText: "Cancel ",
                    })
                        .then(resultado => {
                            if (resultado.value) {
                                // Hicieron click en "Sí"
                                sendEmailVerification(auth.currentUser);
                            }
                        });
                }
            })
            .catch((error) => {
                let errorMessage = error.message;
                console.log(errorMessage);
                Swal.fire({
                    title: "Failed to sign in!",
                    icon: "error",
                });
            });
    };

    const signout = () => {
        const auth = getAuth();
        signOut(auth).then(() => {
            Swal.fire({
                title: "¡Session closed!",
                icon: "success",
            });

            currentUserEmail.value = ""
            sessionStatus.value = ""
        }).catch((error) => {
            console.error(error);
        });
    };

    const onauthstatechanged = () => {
        const auth = getAuth();
        onAuthStateChanged(auth, (user) => {
            if (user) {
                sessionStatus.value = true
            } else { // User is signed out
                sessionStatus.value = false
            }
        });
    };

    const resetPassword = (email) => {
        const auth = getAuth();
        sendPasswordResetEmail(auth, email)
            .then(() => {
                Swal.fire({
                    title: "Email sent!",
                    icon: "success",
                });
            })
            .catch((error) => {
                const errorMessage = error.message;
                console.log(errorMessage);
                // ..
            });
    };

    watchEffect(() => {
        sessionStorage.setItem('sessionStatus', sessionStatus.value) // Llamar a la acción con el usuario activo
        sessionStorage.setItem('currentUserEmail', currentUserEmail.value)
    });

    return {
        register,
        login,
        signout,
        onauthstatechanged,
        resetPassword,
        sessionStatus,
        currentUserEmail
    };
});
