import { defineStore } from 'pinia';
import { setDoc, getDocs, getDoc, addDoc, deleteDoc, doc } from 'firebase/firestore';
import { useAuthStore } from '@/stores/auth.js';
import { useRouter } from "vue-router";
import { ref } from "vue";
import { db } from '../main';

export const useCrudStore = defineStore('crud', () => {

    /* global Swal */
    const router = useRouter()



    // 1. Obtener la información del usuario con el email
    const getUserByEmail = async (email) => {
        const userSnapshot = await getDoc(doc(db, "usuarios", email));
        return userSnapshot.data();
    };


    // 2. Agregar usuario con el email
    const addUser = async (nombre, anio, correo) => {
        try {
            // Utiliza el correo electrónico como ID
            const docRef = doc(db, "usuarios", correo);

            // Establece los datos del documento
            await setDoc(docRef, {
                nombre: nombre,
                anionac: anio,
                correo: correo,
                rol: "usuario",
                
            });

            // Agregar una subcolección "historial" al documento creado
            //const historialRef = collection(docRef, 'historial');

        } catch (error) {
            console.error("Error al añadir el documento: ", error);
        }
    };


    // 3. Eliminar usuarios
    const deleteUser = async (id) => {
        try {
            await deleteDoc(doc(db, "usuarios", id));
            console.log("Usuario eliminado");
        } catch (error) {
            console.error("Error al eliminar el usuario: ", error);
        }
    };    


    return {
        getUserByEmail,
        addUser,
        deleteUser,        
    };
});