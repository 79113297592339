<script setup>
import { useAuthStore } from '@/stores/auth.js';
import { ref } from 'vue';

const email = ref(null);
const password = ref(null);


const handleSubmit = () => {
    // Accede al almacén de autenticación
    const authStore = useAuthStore();

    // Llama a la función register del almacén
    authStore.resetPassword(email.value);
};
</script>

<template>
    <div class="p-10 bg-[#1D1B33] absolute w-full xl:h-full lg:h-full md:h-full sm:h-full flex items-center justify-center">
        <div class="bg-[#121123] shadow-lg overflow-hidden rounded-lg w-[75%]">
            <div class="w-full sm:p-5 lg:hidden">
                <img src="../assets/Entrevistron.png" alt="Logo" class="w-[75%] mx-auto py-5">
            </div>
            <form @submit.prevent="handleSubmit">
                <div class="flex space-between items-center justify-center">
                    <div class="lg:w-[50%] hidden lg:block">
                        <div class="p-5 h-full w-full">
                            <img src="../assets/Entrevistron.png" alt="Logo" class="p-3">
                        </div>
                    </div>
                    <div class="xs:w-[50%] sx:w-full p-10">
                        <div>
                            <b class="text-3xl text-white">Recover account</b>
                            <p class="text-white">Enter your email address to recover your account.</p>
                        </div>
                        <br>
                        <div>
                            <div class="flex flex-col">
                                <span class="font-bold mb-1 text-light">
                                    <i class="fa-solid fa-envelope ml-1.5 mr-3 text-blue-700"></i>Email
                                </span>
                                <input type="email" v-model="email" name="email" placeholder="example@example.com"
                                    class="p-4 rounded-lg border border-gray-300 w-full">
                            </div>
                        </div>
                        <br>
                        <button type="submit"
                            class="transition p-4 w-full bg-blue-700 hover:bg-blue-600 text-white rounded-lg">
                            <b>Send email</b>
                        </button>
                        <br>
                        <br>
                        <div class="text-center">
                            <router-link to="/signin" class="text-blue-700 hover:text-white cursor-pointer">
                                <b>Don't have an account?</b>
                            </router-link>
                        </div>
                        <div class="text-center">
                            <router-link to="/login" class="text-white hover:text-blue-700 cursor-pointer">
                                <b>Sign in again</b>
                            </router-link>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

