import { createRouter, createWebHistory } from 'vue-router'
import {useCounterStore} from "../stores/codes";
import { createPinia } from 'pinia';
import { createApp } from 'vue'
import App from '../App.vue'

import LoginView from "../views/LoginView.vue"
import RegisterView from "../views/SignUpView.vue"
import HomeView from "../views/HomeView.vue"
import ForgotPasswordView from "../views/ForgotPasswordView.vue"

//Monto desde aquí esto ya que son módulos externos que no pueden obtener la info de pinia automáticamente
const pinia = createPinia()
const app = createApp(App)
app.use(pinia)

const codeStore = useCounterStore();

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'home',
      component: HomeView,
      meta: { requiresAuth: true } // Esta ruta requiere autenticación
    },
    {
      path: '/login',
      name: 'login',
      component: LoginView
    },
    {
      path: '/signin',
      name: 'signin',
      component: RegisterView
    },

    {
      path: '/forgot-password',
      name: 'forgot-password',
      component: ForgotPasswordView
    },

    {
      path: '/hub',
      name: 'hub',

      component: () => import('../views/HubView.vue')
    },
    {path: '/interview/:id',
    name: 'entrevista',
    // route level code-splitting
    // this generates a separate chunk (About.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/InterviewView.vue')
    },
    {path: '/video',
    name: 'video',
    // route level code-splitting
    // this generates a separate chunk (About.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/VideoView.vue')
    },
  ]  
})

export default router
