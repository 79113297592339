<script setup>

/* global Swal */
import { useAuthStore } from '@/stores/auth.js';
import { ref } from 'vue';

const name = ref(null);
const birthYear = ref('');
const email = ref(null);
const password = ref(null);
const v_pass = ref(null);

const handleSubmit = () => {
   // Accede al almacén de autenticación
   const authStore = useAuthStore();

   // Llama a la función register del almacén
   authStore.register(name.value, birthYear.value, email.value, password.value, validateFields());
 };

const validateYear = (event) => {
  const value = parseInt(event.target.value);
  if (value < 1950 || value > 2016 || isNaN(value)) {
    event.target.setCustomValidity("El año debe estar entre 1950 y 2016");
  } else {
    event.target.setCustomValidity("");
  }
};

const validateFields = () => {

  if (name.value !== null && birthYear.value !== '' && email.value !== null && password.value !== null && v_pass.value !== null) {
    if (birthYear.value < 1950 || birthYear.value > 2016) {
      Swal.fire({
        title: "Error",
        text: "The year must be between 1950 and 2016.",
        icon: "error",
      });
      return false;
    } else if (!checkPassword(password.value) || !checkPassword(v_pass.value)) {
      console.log("Error: The password does not meet the requirements.");
      Swal.fire({
        title: "Error",
        text: "The password does not meet the requirements: it must be between 8 and 15 characters, contain at least one uppercase letter, one lowercase letter, one number, and one special character.",
        icon: "error",
      });
      return false;
    } else if (checkPassword(password.value) && checkPassword(v_pass.value) && password.value !== v_pass.value) {
      console.log("Error: The passwords do not match.");
      Swal.fire({
        title: "Error",
        text: "The passwords do not match.",
        icon: "error",
      });
      return false;
    } else if (checkPassword(password.value) && checkPassword(v_pass.value) && password.value === v_pass.value) {
      console.log("Success: User created");
      Swal.fire({
        title: "Success!",
        text: "User created successfully",
        icon: "success",
      });
      return true;
    } else {
      console.log("Error: Please verify the fields.");
      Swal.fire({
        title: "Error",
        text: "Please verify the fields.",
        icon: "error",
      });
      return false;
    }
  } else {
    console.log("Error: Please fill in all fields.");
    Swal.fire({
      title: "Error",
      text: "Please fill in all fields.",
      icon: "error",
    })
    return false;
  }
};

const checkPassword = (password) => {
  const tamanioValid = /^(?=.{8,15})/;
  const minusculaValid = /^(?=.*[a-z])/;
  const mayusculaValid = /^(?=.*[A-Z])/;
  const numeroValid = /^(?=.*[0-9])/;
  const caracterValid = /^(?=.*[!@#\$%^&*])/;

  return (
    tamanioValid.test(password) &&
    minusculaValid.test(password) &&
    mayusculaValid.test(password) &&
    numeroValid.test(password) &&
    caracterValid.test(password)
  );
};
</script>


<template>
  <div class="p-10 bg-[#1D1B33] absolute w-full flex items-start justify-center align-center">
    <div class="bg-[#121123] shadow-lg overflow-hidden rounded-lg w-full xl:w-[80%]" name="formRegistro">
      <div class="w-full sm:p-5 lg:hidden">
        <img src="../assets/Entrevistron.png" alt="Logo" class="w-[75%] mx-auto py-5">
      </div>
      <form @submit.prevent="handleSubmit">
        <div class="flex items-center justify-center">
          <div class="flex-none lg:w-[415px] hidden lg:block">
            <div class="p-10 h-full w-full flex justify-center">
              <img src="../assets/Entrevistron.png" alt="Logo" class="max-w-xs">
            </div>
          </div>
          <div class="w-full p-10">
            <div>
              <b class="text-3xl text-white">Sign up</b>
              
            </div>
            <br>
            <div>
              <div class="flex flex-col">
                <span class="font-bold mb-1 text-light">
                  <i class="fa-solid fa-user ml-1.5 mr-3 text-blue-700"></i>Name
                </span>
                <input type="text" v-model="name" name="name" placeholder="Full name"
                  class="p-4 rounded-lg border border-gray-300 w-full">
              </div>
            </div>
            <br>
            <div>
              <div class="flex flex-col">
                <span class="font-bold mb-1 text-light">
                  <i class="fa-solid fa-calendar ml-1.5 mr-3 text-blue-700"></i>Year of birth
                </span>
                <input type="number" v-model="birthYear" name="birthYear" placeholder="AAAA"
                  class="p-4 rounded-lg border border-gray-300 w-full"
                  pattern="^(195[0-9]|19[6-9][0-9]|200[0-9]|201[0-6])" @input="validateYear" />
              </div>
            </div>
            <br>
            <div>
              <div class="flex flex-col">
                <span class="font-bold mb-1 text-light">
                  <i class="fa-solid fa-envelope ml-1.5 mr-3 text-blue-700"></i>Email
                </span>
                <input type="email" v-model="email" name="email" placeholder="example@example.com"
                  class="p-4 rounded-lg border border-gray-300 w-full">
              </div>
            </div>
            <br>
            <div>
              <div class="flex flex-col">
                <span class="font-bold mb-1 text-light">
                  <i class="fa-solid fa-lock ml-1.5 mr-3 text-blue-700"></i>Password
                </span>
                <input type="password" v-model="password" id="password" name="password" placeholder="•••••••••••"
                  @input="checkPassword($event.target.value)" minlength="8" maxlength="15" required
                  class="p-4 rounded-lg border border-gray-300 w-full">
              </div>
            </div>
            <br>
            <div>
              <div class="flex flex-col">
                <span class="font-bold mb-1 text-light">
                  <i class="fa-solid fa-check-double ml-1.5 mr-3 text-blue-700"></i>Password verification
                </span>
                <input type="password" v-model="v_pass" id="v_pswd" name="v_pswd" placeholder="•••••••••••"
                  @input="checkPassword($event.target.value)" minlength="8" maxlength="15" required
                  class="p-4 rounded-lg border border-gray-300 w-full">
              </div>
            </div>
            <br>
            <button @click="validateFields"
              class="transition p-4 w-full bg-blue-700 hover:bg-blue-600 text-white rounded-lg">
              <b>Sign up<i class="fa fa-log-in"></i></b>
            </button>
            <br>
            <br>
            <div class="text-center">
              <router-link to="/login" class="text-blue-700 hover:text-white cursor-pointer">
                <b>Do you already have an account?</b>
              </router-link>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>


<style scoped>
.validation ul {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.validation ul li {
  list-style: none;
  font-size: 0.9em;
  transition: 0.5s;
}

.validation ul li.valid {
  color: #11727C;
  display: inline;
}

.validation ul li .valid i.text-green-500 {
  display: inline;
  /* Mostrar el ícono verde */

}

/* Estilo para los íconos rojos */
.validation ul li .valid i.text-red-500 {
  display: none;
  /* Ocultar el ícono rojo */
}
</style>  