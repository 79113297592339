<script setup>
import { useAuthStore } from '@/stores/auth.js';
import NavBarPrincipal from './components/NavBarPrincipal.vue';



const authStore = useAuthStore()
</script>

<template>
  <NavBarPrincipal/>
  <div class="flex justify-center " >
    <div :class="{ 'w-full':!authStore.
    currentUserEmail, 'mt-12 w-10/12':authStore.
    currentUserEmail}">
    
      <RouterView/>
    </div>
    
  </div>


  <div id="bottom-banner" class="flex bg-black justify-center p-1">
    <img src="./assets/icon.ico"  style="width: 25px;"> <span class="text-[12px] ml-2 mt-1"> ThinkQ | All Rights Reserved | <a target="_blank" href="thinkq.com.ec" class="text-blue-500">www.thinkq.com.ec</a> </span>
  </div>
</template>

<style>
  #bottom-banner {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    color: #fff;
    text-align: center;
  }
</style>