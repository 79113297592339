<script setup>
  import { RouterLink, RouterView, useRouter} from 'vue-router'
  import { ref, onMounted, onBeforeUnmount  } from "vue";
  import { useCounterStore } from '../stores/codes';
  import QRCode from 'qrcode';




  //Variable de router
  const router = useRouter();

  // Variable para el qr
  const qrCodeData = ref('');
  onMounted(() => {
    const currentURL = window.location.origin;
    QRCode.toDataURL(currentURL)
      .then((url) => {
        qrCodeData.value = url;
      })
      .catch((error) => {
        console.error(error);
      });
  });


  //Variables importadas del store
  
  const codeStore = useCounterStore();
  
  // Bool para controlar la animación del logo inicial
  
  const isFlashing = ref(false);
  const flashCount = ref(0);

  const startFlashing = () => {
  isFlashing.value = true; // Activa la animación de destello

  setTimeout(() => {
    isFlashing.value = false; // Restablece la animación después de 1 segundo
    flashCount.value += 1; // Incrementa el contador
  }, 3000);
};

let countdownFlashing;

onMounted(() => {

  countdownFlashing = setInterval(() => {
        if (flashCount.value < 9) {
          
          startFlashing();
        } else {
          clearInterval(countdownFlashing); // Detén el intervalo después de 7 veces
        }
      }, 7000);
});

onBeforeUnmount(() => {
  clearInterval(countdownFlashing); // Limpia el intervalo cuando el componente se desmonta
});


  // Bool para controlar animación de timer

  const isHeadShaking2 = ref(false);

  const startHeadShaking = () => {
    isHeadShaking2.value = true;

    setTimeout(() => {
      isHeadShaking2.value = false; // Restablece la animación después de 1 segundo
    }, 1000);
  };

  const countdown = setInterval(() => {
    if (codeStore.timer === 0) {
      clearInterval(countdown);
      isHeadShaking2.value = false;
    } else if (codeStore.timer < 152 && !isHeadShaking2.value) {
      console.log("Hola, sí debería moverme");
      startHeadShaking();
    }
  }, 2000);


  
  //Código para manejo del evento cuando aplastan enter en vez de dar click al botón
  const handleStart = () => {
    
    codeStore.logoVisible = false
    router.push(`/video`);
    
  }
  

  //Código para ocultar el entrevistrón si no estoy en el menú principal

  const dominio = ref('');

  onMounted(() => {
  // Obtener la URL actual
  const url = window.location.href;

  // Parsear la URL para extraer el slug
  const urlObj = new URL(url);
  const pathSegments = urlObj.pathname.split('/');
  
  // El slug es el último segmento de la ruta (después del último '/')
  dominio.value = pathSegments[pathSegments.length - 1];
  
    if (dominio.value == "") {
      codeStore.logoVisible = true;
    } else {
      codeStore.logoVisible = false;
    }

  });


</script>

<template >

  
<!-- Interfaz -->
  <div v-if="codeStore.logoVisible" class="animate__animated animate__jackInTheBox text-center mt-5 min-h-[100vh]">
    <img 
      :class="{
        'animate__animated': true,
        'animate__flash': isFlashing,
        'mb-5': true,
      }"
      src="../assets/Entrevistron.png"
    />
    <div  class="animate__animated animate__fadeInLeft text-center text-light">
      <button @click="handleStart()" class="btn btn-lg btn-primary mb-3"><b>START</b></button>
    </div>
  </div>
  
  <!-- Interfaz End -->
</template>

<style>
  .bg-black-blue-custom{
    background-color: #121123;
  }

  .logo {
    max-height: 75px;
    width: auto;
  }

  .qrimg {
  width: 20vw;
}

@media (max-width: 600px) {
  .qrimg {
    width: 200px;
  }
}

  .no-select{
    user-select: none;
  }

  h1, h2, h3 {
  font-family: 'Orbitron', sans-serif;
  }
</style>