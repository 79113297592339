import './assets/main.css'

import { createApp } from 'vue'
import { createPinia } from 'pinia'

import App from './App.vue'
import router from './router'

import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getAuth, onAuthStateChanged } from "firebase/auth";

import firebaseConfig from "./fire/config.js";


// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const db = getDatabase(firebaseApp)

const auth = getAuth(); // Obtiene la instancia de autenticación

onAuthStateChanged(auth, (user) => {
    
    const isAuthenticated = !!user; // Verifica si el usuario está autenticado
  
    const isLoginPage = router.currentRoute.value.name === 'login';
    console.log("isLoginPage", isLoginPage)
    console.log("isAuthenticated",isAuthenticated)
  
    if (isAuthenticated && isLoginPage) {
      // Si el usuario está autenticado y está en la página de inicio de sesión, redirige a la página principal
      console.log("Entre /")
      router.next('/');
    } else if (!isAuthenticated && !isLoginPage) {
      // Si el usuario no está autenticado y no está en la página de inicio de sesión, redirige a la página de inicio de sesión
      console.log("Entre /login")
      router.push('/login');
    }
  });


  createApp(App)
  .use(createPinia())
  .use(router)
  .mount("#app");

export { db };